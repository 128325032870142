<template>
  <div class="card" ref="card">
    <router-link :to="{ name: 'Service', params: { direction: type, service: service.image } }">
      <div class="card__header">
        <img :src="imageURL" :alt="service.title">
        <h4 class="card__title">{{ service.title }}</h4>
        <div class="card__header-filter"></div>
      </div>
    </router-link>
    <div>
      <p class="card__text">{{ service.text }}</p>
    </div>
  </div>
</template>

<script>
const serviceTypes = {
  marketing: '0, 192, 192',
  development: '231, 138, 29',
  design: '46,	255,	12'
}

export default {
  props: {
    service: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.$refs.card.style.setProperty('--color', serviceTypes[this.type])
  },
  computed: {
    imageURL() {
      return require(`../assets/directions/services/${this.service.image}.png`)
    },
  }
}
</script>

<style scoped>
.card {
  width: 100%;
  text-align: left;
}
.card__header {
  width: 100%;
  height: 15.5em;
  margin-bottom: var(--lg);
  box-sizing: border-box;
  overflow: hidden;

  position: relative;
  cursor: pointer;
}

.card__header-filter {
  position: absolute;
  content: '';
  display: block;
  z-index: 1;
  top: 0;  left: 0;  right: 0;  bottom: 0;
  background: linear-gradient(180deg, rgba(25, 25, 25, 0) 0%, rgba(25, 25, 25, 0.932292) 100%);
}
.card__header:hover .card__header-filter {
  background: linear-gradient(180deg, rgba(var(--color), 0) 0%, rgba(var(--color), 0.932292) 100%);
}
.card__header:active .card__header-filter {
  -webkit-box-shadow: 0px 0px 0px 4px rgb(var(--color)) inset;
  -moz-box-shadow: 0px 0px 0px 4px rgb(var(--color)) inset;
  box-shadow: 0px 0px 0px 4px rgb(var(--color)) inset;
}

.card__header img {
  width: 100%;
  object-fit: contain;
  filter: grayscale(1);
}
.card__header:hover img {
  filter: grayscale(0);
}

.card__title {
  position: absolute; /* Делаем абсолют, потому что используем img как фон, а не background. Img нужен, чтобы юзать filter. ибо фильтры для бэкграундов пока не работают в Firefox*/
  left: var(--lg);
  right: var(--lg);
  bottom: var(--lg);

  z-index: 100;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: break-word;

  font-size: 1.375rem;
  color: #FFFFFF;
  margin: 0;
}

.card__text {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 140%;
  color: var(--light-black);
  margin: 0;
}
@media (max-width: 425px) {
  .card__text {
    font-size: 0.875rem;
  }
}
</style>
