<template>
  <div class="direction">
    <div class="direction__header">
      <div class="direction__info">
        <h2>{{ directions[direction].title }}</h2>
        <p>{{ directions[direction].description }}</p>
        <BaseButton @click.native="$root.$emit('triggerModal', $event)">Заказать</BaseButton>
      </div>

      <div class="direction__img">
        <div class="direction__radius" :style="{ background: activeColor }"></div>
        <component class="direction__svg" :is="directions[direction].icon"></component>
      </div>
    </div>

    <div class="direction__blogs" :key="direction">
      <ServiceCard
          v-for="(service, index) in services[direction]"
          :key="index"
          :service="service"
          :type="direction"
      />
    </div>

    <div class="direction__right">
        <p>Мы предоставляем услуги аутстаффинга для специалистов любых направлений</p>
        <BaseButton outline @click.native="$root.$emit('triggerModal', $event)">Оставить заявку</BaseButton>
        <div class="direction__right-ellipse" :style="{ background: activeColor }"></div>
      </div>
  </div>
</template>

<script>
import DevelopmentIcon from '@/components/Icons/DevelopmentIcon.vue'
import DesignIcon from '@/components/Icons/DesignIcon.vue'
import PromotionIcon from '@/components/Icons/PromotionIcon.vue'

import ServiceCard from '@/components/ServiceCard.vue'

export default {
  name: 'Direction',
  components: { DevelopmentIcon, DesignIcon, PromotionIcon, ServiceCard },
  props: {
    direction: {
      type: String,
      default: 'marketing',
      validator: (value) => ['marketing', 'development', 'design'].includes(value)
    }
  },
  data() {
    return {
      directions: {
        marketing: {
          title: 'Маркетинг',
          description: 'Продвижение товаров и услуг в Интернете используя SMM, SEO и современные инструменты интернет-маркетинга',
          icon: 'PromotionIcon'
        },
        development: {
          title: 'Разработка',
          description: 'Наша команда занимается проекторованием, дизайном и разработкой различных информационных систем, web-сайтов и мобильных приложений для iOS и Android. ',
          icon: 'DevelopmentIcon'
        },
        design: {
          title: 'Дизайн',
          description: 'Проектирование интерфейсов для наилучшего опыта взаимодействия пользователей, самые подходящие графические решения для клиентов',
          icon: 'DesignIcon'
        }
      },
      services: {
        marketing: [
          // {
          //   title: 'SMM',
          //   text: 'За счёт грамотно построенной SMM-стратегии можно повысить узнаваемость, продажи и наладить коммуникации с пользователями',
          //   image: 'smm'
          // },
          {
            title: 'SEO',
            text: 'За счёт грамотной SEO-оптимизации, поисковики приведут на ваш сайт посетителей из числа целевой аудитории',
            image: 'seo'
          },
          {
            title: 'Контекстная реклама',
            text: 'Контекстная реклама - мощный инструмент в продвижении. Запустив контекст, вы охватите широкую аудиторию и получите трафик',
            image: 'context'
          },
          {
            title: 'Таргетированная реклама',
            text: 'Основным плюсом таргетированной рекламы является то, что ее можно настроить без наличия сайта компании',
            image: 'target'
          }
        ],
        development: [
          {
            title: 'Лендинг',
            text: 'Разработка одностраничного сайта согласно дизайн-макетам',
            image: 'landing'
          },
          {
            title: 'Многостраничный сайт',
            text: 'Реализация многостраничного сайта согласно техническому заданию',
            image: 'multipage'
          },
          {
            title: 'Интернет-магазин',
            text: 'Создание полноценного интернет-магазина для любого бизнеса',
            image: 'shop'
          },
          {
            title: 'Индивидуальное решение',
            text: 'Разработка индивидуального IT-решение для потребностей вашей компании',
            image: 'custom'
          },
          {
            title: 'Разработка на CMS',
            text: 'Реализация системы или приложения с использованием известных CMS',
            image: 'CMS'
          },
          {
            title: 'Поддержка систем и приложений',
            text: 'Поддержка созданной нами или вашей уже существующей системы',
            image: 'support'
          },
          {
            title: 'Кроссплатформенное приложение',
            text: 'Такое приложение будет одинаково работать и на операционной системе Android и на операционной системе iOS',
            image: 'cross_platform'
          },
          {
            title: 'Приложение для iOS',
            text: 'Приложение будет создано под устройства с операционной системой iOS',
            image: 'iOS'
          },
          {
            title: 'Приложение для Android',
            text: 'Приложение будет создано под устройства с операционной системой Android',
            image: 'android'
          }
        ],
        design: [
          {
            title: 'Дизайн лендинга',
            text: 'Простой одностраничный сайт, акцент в котором делается на графику и специфику бренда',
            image: 'landing_dis'
          },
          {
            title: 'Дизайн сайта',
            text: 'Комплексное проектирование логики и внешнего вида многостраничного сайта или интернет-магазина',
            image: 'multipage_dis'
          },
          {
            title: 'Дизайн мобильного приложения',
            text: 'Проектирование приложений любой сложности с учетом особенностей платформ и привычек пользователей',
            image: 'mobile'
          },
          {
            title: 'Брендинг',
            text: 'Разработка фирменного стиля, айдентики. Позволяет выделить вашу компанию среди других на рынке',
            image: 'brand'
          },
          {
            title: 'Дизайн социальных сетей',
            text: 'Оформление страниц в различных социальных сетях на основе готового брендинга. Подходит для долгого использования',
            image: 'social_media'
          }
        ]
      }
    }
  },
  computed: {
    activeColor() {
      return `var(--${this.direction}-grad)`
    }
  }
}
</script>

<style scoped>
.direction {
  width: 81.875em;
  margin: 0 auto;
  box-sizing: border-box;
}
@media (max-width: 1367px) {
  .direction {
    width: 100%;
    padding: 0 65px;
  }
}
@media (max-width: 1025px) {
  .direction {
    padding: 0 40px;
  }
}
@media (max-width: 426px) {
  .direction {
    padding: 0 25px;
  }
}
@media (max-width: 321px) {
  .direction {
    padding: 0 15px;
  }
}


.direction__header {
  display: flex;
  width: 100%;
  padding: 9.0625em 0;
  align-items: center;
  position: relative;
}
@media (max-width: 720px) {
  .direction__header {
    padding: 3.125em 0 5em;
    position: static;
  }
}
.direction__info {
  display: flex;
  flex-direction: column;
  max-width: 34.375em;
}
@media (max-width: 720px) {
  .direction__info {
    max-width: 20em;
  }
}

.direction__info h2 {
  margin: 0 0 var(--xxl);
}
@media (max-width: 425px) {
  .direction__info h2 {
    font-size: 1.625rem;
  }
}

.direction__info p {
  font-size: 1.125rem;
  margin: 0 0 2.22em;
  line-height: 140%;
  text-align: left;

  color: var(--light-black);
}
@media (max-width: 720px) {
  .direction__info p {
    font-size: 1rem;
  }
}
@media (max-width: 425px) {
  .direction__info p {
    font-size: 0.875rem;
  }
}

.direction__img {
  position: absolute;
  width: 38.9375em;
  top: 6.375em;
  right: 2.1875em;
  box-sizing: border-box;
  /* overflow-y: hidden; */
  z-index: -1;
}

.direction__radius {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: var(--marketing-grad);
  opacity: 0.8;
}

.direction__img::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  z-index: 1000;
  background: linear-gradient(10.37deg, #F8F8F8 45%, rgba(248, 248, 248, 0) 90.6%);
}

@media (max-width: 1237px) {
  .direction__img {
    right: min(2.1875em, calc(100vw - 72em));
  }
}
@media (max-width: 720px) {
  .direction__img {
    top: calc(49vw - 36.5em);
    right: calc(15vw - 24.5em);
  }
  .direction__radius {
    transform: rotate(-90deg);
  }

  .direction__img::before {
    transform: rotate(-90deg);
  }
}

.direction__svg {
    width: 100%;
    height: auto;
    fill: var(--black);
}

.direction__blogs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3.125em;
}
@media (max-width: 1255px) {
  .direction__blogs {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3.75em;
  }
}
@media (max-width: 950px) {
  .direction__blogs {
    grid-gap: 2.5em;
  }
}
@media (max-width: 900px) {
  .direction__blogs {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 600px) {
  .direction__blogs {
    grid-template-columns: 1fr;
  }
}
.direction__right {
  margin: 8.75em 0 14.375em auto;
  max-width: 19.8125em;
  text-align: right;
  align-self: flex-end;
  position: relative;
}
@media (max-width: 900px) {
  .direction__right {
    margin: 11.25em 0 14.375em auto;
  }
}
@media (max-width: 600px) {
  .direction__right {
    margin: 5.3125em 0 5.3125em auto;
  }
}
.direction__right p {
  font-size: 1rem;
  line-height: 140%;
  text-align: right;
  color: var(--light-black);
  margin-bottom: var(--xl);
}
@media (max-width: 425px) {
  .direction__right p {
    font-size: 0.875rem;
  }
}

.direction__right-ellipse {
  position: absolute;
  content: '';
  right: -50%;
  top: -100%;
  width: 37.3vw;
  height: 37.3vw;
  z-index: -1;
  background: var(--marketing-grad);
  opacity: 0.4;
}
</style>
